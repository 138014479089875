import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useNotifications } from '../contexts/NotificationContext';
import Badge from '@mui/material/Badge';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const NotificationList = () => {

    const { notifications, setNotifications } = useNotifications();
    const [open, setOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        setOpen(open);
    };

    const fetchNoti = useCallback(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/notifications/get/1`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            let data = [];
            res.data.notifications.forEach((item) => {
                data.push({
                    title: item.noti_title,
                    message: item.noti_message,
                    id: item.id
                });
            });
            setNotifications(data)
        }).catch(err => console.log(err));
    }, [setNotifications]);

    useEffect(() => {
        fetchNoti();
    }, [fetchNoti]);


    const markAsRead = (id) => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/notifications/mark-as-read/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('admin_token')}`
            }
        }).then(res => {
            fetchNoti();
        }).catch(err => console.log(err));
    }


    const list = () => (
        <div
            role="presentation"
            //onClick={toggleDrawer(false)}
            //onKeyDown={toggleDrawer(false)}
            style={{ width: 280 }}
        >
            <div className='flex justify-between items-center px-4 pt-2'>
                <span className='font-bold'>การแจ้งเตือน</span>
                <IconButton onClick={toggleDrawer(false)}>
                    <CloseOutlinedIcon />
                </IconButton>
            </div>
            {notifications.length === 0 ? (
                <div className='flex justify-center items-center h-full'>
                    <span className='text-gray-400'>ไม่มีการแจ้งเตือน</span>
                </div>
            ) : (
                <List>
                    {notifications.map((notification, index) => (
                        <ListItem
                            key={index}
                            className='border-t'
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => markAsRead(notification.id)}
                                >
                                    <ClearOutlinedIcon />
                                </IconButton>
                            }
                        >
                            <ListItemText
                                primary={notification.title}
                                secondary={notification.message}
                            />
                        </ListItem>
                    ))}
                    {notifications.length > 20 && (
                        <ListItem className='border-t border-b'>
                            <ListItemText className='text-center' primary='ดูทั้งหมด' />
                        </ListItem>
                    )}
                </List>
            )}

        </div>
    );

    return (
        <>
            <IconButton
                onClick={toggleDrawer(true)}
                sx={{ backgroundColor: '#f5f5f5' }}
            >
                <Badge
                    badgeContent={notifications.length}
                    color="primary"
                >
                    <NotificationsOutlinedIcon />
                </Badge>
            </IconButton>
            <Drawer
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </>
    );

}

export default NotificationList;