import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export const NavigationContext = React.createContext();

export const NavigationProvider = ({ children }) => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const navigate = useNavigate();

    const requestNavigation = useCallback((location) => {
        if (hasUnsavedChanges) {
            setDialogOpen(true);
            setNextLocation(location);
        } else {
            navigate(location);
        }
    }, [hasUnsavedChanges, navigate]);

    const confirmNavigation = () => {
        setDialogOpen(false);
        setHasUnsavedChanges(false);
        navigate(nextLocation);
    };

    const cancelNavigation = () => {
        setDialogOpen(false);
        setNextLocation(null);
    };

    return (
        <NavigationContext.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges, requestNavigation }}>
            {children}
            <Dialog
                open={dialogOpen}
                onClose={cancelNavigation}
            >
                <DialogTitle>Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have unsaved changes. Do you really want to leave?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelNavigation} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmNavigation} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </NavigationContext.Provider>
    );
};
