import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import toast from 'react-hot-toast';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
            query: {
                token: localStorage.getItem('admin_token')
            }
        });

        socket.on('newNotification', (notification) => {
            setNotifications((prevNotifications) => [ notification, ...prevNotifications]);
            toast.custom((t) => (
                <div
                    className={`${t.visible ? 'animate-enter' : 'animate-leave'
                        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                    <div className="flex-1 w-0 p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0 pt-0.5">
                                <NotificationsOutlinedIcon />
                            </div>
                            <div className="ml-3 flex-1">
                                <p className="text-sm font-medium text-gray-900">
                                    {notification.title}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    {notification.message}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex border-l border-gray-200">
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-col1 hover:text-col1 focus:outline-none focus:ring-2 focus:ring-col1"
                        >
                            Close
                        </button>
                    </div>
                </div>
            ));
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <NotificationContext.Provider value={{ notifications, setNotifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => {
    return useContext(NotificationContext);
};
