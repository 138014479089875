import { subDays } from "date-fns";

export const predefinedBottomRanges = [
    {
        label: '7 วัน',
        value: [subDays(new Date(), 7), new Date()]
    },
    {
        label: '1 เดือน',
        value: [subDays(new Date(), 30), new Date()]
    },
    {
        label: '3 เดือน',
        value: [subDays(new Date(), 90), new Date()]
    },
    {
        label: '6 เดือน',
        value: [subDays(new Date(), 180), new Date()]
    },
    {
        label: '1 ปี',
        value: [subDays(new Date(), 365), new Date()]
    }
];