import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { predefinedBottomRanges } from '../components/predefinedBottomRanges';
//import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const adminRole = JSON.parse(localStorage.getItem('admin_role'));

const ExportSubmissions = () => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [alert, setAlert] = useState({ open: false, type: 'success', msg: '', });
    const [open, setOpen] = useState(false);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState('');


    const fetchData = () => {

        if (selectedQuestion === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกแบบสอบถาม',
            });
            return;
        }

        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/submissions/export`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
            },
            params: {
                startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                questionnaireId: selectedQuestion,
            }
        }).then((response) => {
            const nestedData = response.data.data;
            const processedData = nestedData.map(submission => {
                return submission.reduce((acc, current) => {
                    let label = current.q_label || (current.q_input_type === "address" ? "Address" : "Unnamed Field");

                    let answer = current.answer;
                    if (current.q_input_type === 'address' && answer !== null && typeof answer === 'object') {
                        // Process the address object. Modify this according to your actual address structure.
                        // This is just an example based on the provided data structure.
                        answer = `${answer.tambon.name_th}, ${answer.amphure.name_th}, ${answer.province.name_th}`;
                    } else if (Array.isArray(answer) || (answer !== null && typeof answer === 'object')) {
                        // Join array or object values with a comma
                        answer = Object.values(answer).join(', ');
                    }

                    acc[label] = answer;
                    return acc;
                }, {});
            });


            // Create a new workbook and add a worksheet
            const ws = XLSX.utils.json_to_sheet(processedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Submissions");

            // Export the workbook
            const exportFileName = `Submissions_${dayjs().format('YYYYMMDD')}.xlsx`;
            XLSX.writeFile(wb, exportFileName);


            setIsLoading(false);
            setOpen(false);
        }).catch((err) => {
            setIsLoading(false);
            if (err.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: err.response.data.error,
                });
            }
        })
    }

    useEffect(() => {
        if (adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER')) {

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/questionnaires/get/1?page=1`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
                },
            }).then((response) => {
                setQuestions(response.data.data);
            }).catch((err) => {
                if (err.response.data.error) {
                    setAlert({
                        open: true,
                        type: 'error',
                        msg: err.response.data.error,
                    });
                }
            })
        }
    }, []);


    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)}>Export as Excel</Button>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                width='sm'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>เลือกวันที่ต้องการส่งออกข้อมูล</h2>
                    <div>
                        <label className='block mb-1'>ช่วงเวลา</label>
                        <DateRangePicker
                            //placement="bottomEnd"
                            size="lg"
                            placeholder="เลือกช่วงเวลา"
                            value={[filterDate.startDate, filterDate.endDate]}
                            onChange={(value) => {
                                setFilterDate({
                                    startDate: new Date(dayjs(value[0]).format('YYYY-MM-DD')),
                                    endDate: new Date(dayjs(value[1]).format('YYYY-MM-DD')),
                                });
                            }}
                            ranges={predefinedBottomRanges}
                            className='w-full'
                        />
                        <div className='h-8'></div>
                        <FormControl
                            className='w-full'
                            size='small'
                        >
                            <InputLabel>แบบสอบถาม</InputLabel>
                            <Select
                                value={selectedQuestion}
                                onChange={(e) => setSelectedQuestion(e.target.value)}
                                className='w-full mt-0'
                                label="แบบสอบถาม"
                            >
                                {questions.map(question => (
                                    <MenuItem key={question.questionnaire_id} value={question.questionnaire_id}>{question.q_title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </div>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button disabled={isLoading} className='btn btn-danger' onClick={fetchData}>ส่งออกข้อมูล</Button>
                    </div>
                </div>
            </Dialog>

            <Snackbar
                className='z-50'
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => setAlert({ ...alert, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ExportSubmissions;