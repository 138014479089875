import TextField from '@mui/material/TextField';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';

const DebounceSearch = ({ fetchcontents }) => {

    function debounce(func, delay = 300) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const debouncedFetchContents = debounce((searchValue) => {
        fetchcontents(1, searchValue);
    }, 500);

    const handleDebonceSearch = (e) => {
        let value = e.target.value;
        if (value === '') {
            fetchcontents();
            return;
        }
        debouncedFetchContents(value);
    }
    return (
        <TextField
            type='search'
            className='border border-gray-300 rounded-md p-2 outline-none'
            placeholder='ค้นหา'
            onChange={handleDebonceSearch}
            variant="standard"
            size='small'
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchOutlinedIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default DebounceSearch;