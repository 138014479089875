import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from '../../layouts/layout';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ExpensesCategories = () => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [openContenttDialog, setOpenContentDialog] = useState(false);
    const [singleData, setSingleData] = useState({
        category_id: null,
        category_name: '',
        category_dexcription: '',
    });
    const clearSingleData = () => {
        setSingleData({
            category_id: null,
            category_name: '',
            category_dexcription: '',
        });
    }
    const fetchcontents = useCallback((value = 1) => {
        setIsLoading(true)

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expense-categories/get/${value}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.categories);
                setIsLoading(false);
                setAllPage(response.data.totalPage);
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const handleDelete = () => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/expense-categories/delete/${deleteId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpen(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
                fetchcontents();
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    const validateData = () => {
        if (singleData.category_name === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกชื่อหมวดหมู่ค่าใช้จ่าย'
            });
            return false;
        }
        return true;
    }

    const handleAddneworUpdate = (addorupdate) => {

        if (!validateData()) {
            return;
        }

        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/expense-categories/addnew`;
        if (addorupdate === 'update') {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/expense-categories/update/${singleData.category_id}`;
        }
        setIsLoading(true)
        axios({
            method: addorupdate === 'add' ? 'post' : 'put',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: singleData
        }).then(function (response) {
            if (response.status === 200) {
                setIsLoading(false);
                setOpenContentDialog(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: addorupdate === 'add' ? 'เพิ่มข้อมูลสำเร็จ' : 'แก้ไขข้อมูลสำเร็จ'
                });
                fetchcontents();
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.error
                });
            }
        });
    }

    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between items-end w-full'>
                    <div className='flex gap-4 items-end'>
                        <h1 className='text-2xl font-bold hidden lg:block'>หมวดหมู่ค่าใช้จ่าย</h1>
                    </div>
                    <div className='flex gap-4 items-end'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setOpenContentDialog(true);
                                clearSingleData();
                            }}
                        >
                            เพิ่มหมวดหมู่ค่าใช้จ่าย
                        </Button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap bg-white rounded-lg'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-1/2 font-bold'>ชื่อ</div>
                            <div className='w-1/2 text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-1/2'>{content.category_name}</div>
                                <div className='w-1/2 flex justify-end gap-2'>
                                    <Tooltip title="แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" onClick={() => {
                                            setSingleData({
                                                category_id: content.category_id,
                                                category_name: content.category_name,
                                                category_dexcription: content.description,
                                            });
                                            setOpenContentDialog(true);
                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" onClick={() => {
                                            setDeleteId(content.category_id)
                                            setOpen(true);
                                        }}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>

                <div className='sticky bottom-0 w-full p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>ยินยันการลบข้อมูล</h2>
                    <p>ข้อมูลนี้จะถูกลบ และไม่สามารถกู้คืนได้</p>
                    <div className='flex justify-end items-center gap-2 mt-2'>
                        <Button
                            className='btn btn-secondary'
                            onClick={() => {
                                setOpen(false);
                                setDeleteId(null);
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            disabled={isLoading}
                            className='btn btn-danger'
                            onClick={handleDelete}
                        >
                            ลบ
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                onClose={() => setOpenContentDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={openContenttDialog}
                width='lg'
                fullWidth
            >
                <div className='p-6 text-gray-600'>
                    <h2 className='text-xl font-bold mb-4'>{singleData.category_id === null ? 'เพิ่ม' : 'แก้ไข'}หมวดหมู่ค่าใช้จ่าย</h2>
                    <div className='flex flex-col gap-4'>
                        <TextField
                            label="ชื่อ"
                            variant="outlined"
                            value={singleData.category_name}
                            onChange={(e) => {
                                setSingleData({ ...singleData, category_name: e.target.value });
                            }}
                        />
                        <TextField
                            multiline
                            rows={4}
                            label="คำอธิบาย"
                            variant="outlined"
                            value={singleData.category_dexcription}
                            onChange={(e) => {
                                setSingleData({ ...singleData, category_dexcription: e.target.value });
                            }}
                        />
                    </div>


                    <div className='flex justify-end items-center gap-2 mt-4'>
                        <Button
                            onClick={() => {
                                setOpenContentDialog(false);
                                clearSingleData();
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            disabled={isLoading}
                            autoFocus
                            onClick={() => {
                                handleAddneworUpdate(singleData.category_id === null ? 'add' : 'update');
                            }}
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}

export default ExpensesCategories;