import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
    const token = localStorage.getItem('admin_token');
    const role = localStorage.getItem('admin_role');
    if (role === 'undefined' || !role) {
        localStorage.clear();
        return <Navigate to='/auth' replace />;
    }
    return token ? children : <Navigate to='/auth' />;
}
export default PrivateRoute;