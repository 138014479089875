import { useDrag } from 'react-dnd';

const FormElement = ({ type, name, onDragEnd, icon }) => {

    const [{ isDragging }, drag] = useDrag({
        type: 'NEW_FORM_ELEMENT',
        item: { type, name },
       
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });
    
    return (
        <div ref={drag} className='cursor-grab p-4 rounded-lg shadow bg-white text-gray-600 flex gap-4 items-center' style={{ opacity: isDragging ? 0.5 : 1 }}>
            {icon}
            {name}
        </div>
    );
}

export default FormElement;
