import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../layouts/layout";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import dayjs from 'dayjs';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Link } from 'react-router-dom';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

const ActivityCalendar = () => {

    const [filterDate, setFilterDate] = useState({
        startDate: new Date(dayjs().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(dayjs().endOf('month').format('YYYY-MM-DD')),
    });
    const [events, setEvents] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const mapDataToEvents = (data) => {
        return data.flatMap(item =>
            item.activities
                .filter(activity => activity.activity_type === 'นัดหมาย')
                .map(activity => ({
                    id: activity.activity_id,
                    opid: item.opportunity_id,
                    title: activity.activity_title,
                    start: activity.scheduled_date,
                    description: activity.activity_description,
                    customer: item.customer,
                    sale: item.sale,
                }))
        );
    };


    const handleDatesSet = (info) => {
        const { startStr, endStr } = info;
        setFilterDate({
            startDate: new Date(dayjs(startStr).format('YYYY-MM-DD')),
            endDate: new Date(dayjs(endStr).format('YYYY-MM-DD')),
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/report/get-activities`, {
                    params: {
                        startDate: dayjs(filterDate.startDate).format('YYYY-MM-DD'),
                        endDate: dayjs(filterDate.endDate).format('YYYY-MM-DD'),
                        compare: true,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                setEvents(mapDataToEvents(data));

            } catch (error) {
                console.error("Error fetching the data", error);
            }
        }

        fetchData();

    }, [filterDate]);

    const handleEventClick = ({ event }) => {
        setSelectedEvent(event);
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const adminRole = JSON.parse(localStorage.getItem('admin_role'));

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <div className='flex justify-start items-center gap-4'>
                    <h1 className='text-2xl font-bold'>ปฏิทินนัดหมาย</h1>
                    <Link to='/activities' className='px-4 py-2 rounded bg-col1 text-white'>กิจกรรม </Link>
                    <Link to='/activity-calendar' className='px-4 py-2 rounded bg-col2 text-white'>ปฏิทินนัดหมาย </Link>
                </div>
            </div>

            <div className='py-8'>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    datesSet={handleDatesSet}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    eventClick={handleEventClick}
                    //Height of calendar by screen
                    //height='auto'
                />
            </div>

            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                maxWidth='xs'
                fullWidth
            >
                <DialogTitle>{selectedEvent?.title}</DialogTitle>
                <DialogContent>
                    {selectedEvent && (
                        <>
                            <div className='text-gray-500 text-xs'>รายละเอียด</div>
                            <div>{selectedEvent.extendedProps.description}</div>
                            <div className='text-gray-500 text-xs mt-2'>วันที่นัดหมาย</div>
                            <div>{dayjs(selectedEvent.start).format('DD/MM/YYYY HH:mm')}</div>
                            <div className='text-gray-500 text-xs mt-2'>ลูกค้า</div>
                            <div>{selectedEvent.extendedProps.customer}</div>
                            {adminRole.includes('ROLE_ADMIN', 'ROLE_MANAGER') && (
                                <>
                                    <div className='text-gray-500 text-xs mt-2'>พนักงานขาย</div>
                                    <div>{selectedEvent.extendedProps.sale}</div>
                                </>
                            )}
                            <div className='flex justify-end mt-8'>
                                <Link to={`/opportunities/update/${selectedEvent.extendedProps.opid}`}>
                                    ดูรายละเอียดการขาย <ArrowRightAltOutlinedIcon />
                                </Link>
                            </div>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </Layout>
    );
}

export default ActivityCalendar;