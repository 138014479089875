import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { Link } from 'react-router-dom';
const OpportunityList = ({ data }) => {

    const opportunityStatusColors = {
        "New": "#FFD700",
        "Qualified": "#32CD32",
        "Needs Analysis": "#1E90FF",
        "Proposal/Price Quote": "#FFA500",
        "Negotiation": "#FF6347",
        "Verbal Commitment": "#FFDAB9",
        "Closed – Won": "#008000",
        "Closed – Lost": "#FF0000",
        "On Hold": "#D3D3D3",
        "Not Qualified": "#8B0000",
        "Deferred": "#C71585",
        "Transferred": "#20B2AA",
        "Reserve": "#4B0082",
    };

    const convertDateAndTime = (date) => {
        return new Date(date).toLocaleDateString('th-TH', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }) + " " + new Date(date).toLocaleTimeString('th-TH', {
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    return (
        <div className="h-full">
            {data.map((opportunity) => (
                opportunity.opportunity_id &&
                <div key={opportunity.opportunity_id} className="border rounded mb-6 p-4 bg-white">
                    <div className="flex justify-between">
                        <span className="font-bold text-lg">{opportunity.customer}</span>
                        <div className='flex items-center gap-2'>
                            <div className='w-3 h-3 rounded-full' style={{ backgroundColor: opportunityStatusColors[opportunity.op_status] }}></div>
                            {opportunity.op_status}
                        </div>
                    </div>
                    <p className="text-sm mt-2">พนักงานขาย: {opportunity.sale}</p>
                    {/*<p className="text-sm mt-2">Created at: {convertDateAndTime(opportunity.create_at)}</p>*/}
                    <hr className="my-4" />
                    <div className="relative border-l border-gray-200">
                        {opportunity.activities.map((activity) => (
                            <div key={activity.activity_id} className="mb-4 ml-4">
                                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>

                                <span className="font-normal text-gray-600 text-xs">{convertDateAndTime(activity.create_at)}</span>
                                <div className="font-semibold">{activity.activity_title}</div>


                                <p className="text-sm">{activity.activity_description}</p>
                                {
                                    activity.activity_type === 'นัดหมาย' && (
                                        <div className="mt-2">
                                            <span className="text-sm">วันที่นัดหมาย: </span>
                                            <span className="text-sm">{convertDateAndTime(activity.scheduled_date)}</span>
                                        </div>
                                    )
                                }
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end mt-4">
                        <Link to={`/opportunities/update/${opportunity.opportunity_id}`} className="flex items-center gap-1">
                            <span className="text-sm">ดูรายละเอียด</span>
                            <ArrowRightAltOutlinedIcon />
                        </Link>
                    </div>
                </div>
            ))}
            {
                data.length === 0 && (
                    <div className="text-center h-full flex items-center justify-center">
                        <span className="text-gray-400">ไม่มีข้อมูล</span>
                    </div>
                )
            }
        </div>
    );
};

export default OpportunityList;

