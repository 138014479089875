import Layout from "../../layouts/layout";
//import mockData from '../../json/mock_submissions.json';
//import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main styles
import 'react-date-range/dist/theme/default.css'; // theme styles

const Reports = ({clidren}) => {
    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Report</h1>
                <div className='grid grid-cols-12'>

                    <div className="col-span-3">

                    </div>
             

                </div>
            </div>
        </Layout>
    );
}

export default Reports;