import { useState, forwardRef, useRef } from "react"
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Turnstile } from '@marsidev/react-turnstile'

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login() {

    const cfref = useRef(null);
    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    const submit = async (e) => {
        e.preventDefault();
        if (username === '') {
            setErrorMsg('กรุณากรอกชื่อผู้ใช้');
            setOpen(true);
            return;
        }
        if (password === '') {
            setErrorMsg('กรุณากรอกรหัสผ่าน');
            setOpen(true);
            return;
        }
        setDisabled(true);

        const formData = new FormData(formRef.current)
        let cftoken = formData.get('cf-turnstile-response');

        if (typeof cftoken !== 'string') {
            cftoken = '';
        }

        if (cftoken === '') {
            setErrorMsg('กรุณายืนยันตัวตน');
            setOpen(true);
            setDisabled(false);
            return;
        }

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                username: username,
                password: password,
                cftoken: cftoken
            }
        }).then(function (response) {
            localStorage.setItem('admin_token', response.data.token);
            localStorage.setItem('admin_role', JSON.stringify(response.data.role));
            localStorage.setItem('admin_username', response.data.user);
            navigate('/');
        }).catch(function (error) {
            setDisabled(false);
            setErrorMsg('เกิดข้อผิดพลาดในการเชื่อมต่อ');
            setOpen(true);
        });

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <div className="min-h-screen flex flex-col justify-between items-center bg-slate-50 rounded-r-lg">

                <div></div>

                <div className="max-w-sm">
                    <div className="logo-warp w-full text-center flex justify-center">
                        <img src="/favicon.png" alt="logo" className="w-28" />
                    </div>

                    <div className=" w-full bg-white p-8 rounded-lg">
                        <form ref={formRef} style={{ maxWidth: '300px' }}>
                            <h1 className="text-3xl font-bold mb-2">เข้าสู่ระบบ</h1>
                            <p className="mb-8">กรอกชื่อผู้ใช้ และรหัสผ่าน เพื่อเข้าสู่ระบบ</p>
                            <div className="mb-4 w-full">
                                <TextField fullWidth label="ชื่อผู้ใช้" variant="outlined" onKeyUp={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="mb-4 w-full">
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor={'password'}>รหัสผ่าน</InputLabel>
                                    <OutlinedInput
                                        id={'password'}
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={'รหัสผ่าน'}
                                        fullWidth
                                        variant="outlined"
                                        onKeyUp={(e) => setPassword(e.target.value)}
                                    />
                                </FormControl>
                            </div>


                            <div className='text-center py-4'>
                                <Turnstile siteKey={`${process.env.REACT_APP_CF_SITE_KEY}`} ref={cfref} theme="light" />
                            </div>

                            <button className="w-full bg-col1 text-white py-3 rounded-md" onClick={submit} disabled={disabled}>เข้าสู่ระบบ</button>
                        </form>
                    </div>
                </div>
                <div className="copy-right w-full flex justify-center items-end pb-6">
                    <p>© 2016-{new Date().getFullYear()}
                        <a href="https://8content.com" rel="noreferrer" target="_blank"> astinestate.com</a>
                    </p>
                </div>
            </div>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
        </>
    )
}