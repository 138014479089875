import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

const DeleteDialog = ({ open, setOpen, handleDelete, dialogTitle, dialogDescription, setDeleteId, isLoading }) => {
    return (
        <Dialog
            onClose={() => setOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={open}
            width='lg'
            fullWidth
        >
            <div className='p-6 text-gray-600'>
                <h2 className='text-xl font-bold mb-4'>{dialogTitle}</h2>
                <p>{dialogDescription}</p>
                <div className='flex justify-end items-center gap-2 mt-2'>
                    <Button
                        className='btn btn-secondary'
                        onClick={() => {
                            setOpen(false);
                            setDeleteId(null);
                        }}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        disabled={isLoading}
                        className='btn btn-danger'
                        onClick={handleDelete}
                    >
                        ลบ
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default DeleteDialog;